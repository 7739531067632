import { Formik } from 'formik'
import _ from 'lodash'
import React from 'react'
import { useParams } from 'react-router-dom'
import { BFF_PROXY_PATHS } from 'src/constants/paths'
import { useOrganisationContext } from 'src/context'
import { useAxios, useToast } from 'src/hooks'
import { genUUID } from 'src/utils'

import { Modal } from '../../../components'
import schema from '../Tariff/Tariff.schema'
import TariffForm from '../Tariff/TariffForm'

interface Props {
  isOpen: boolean
  tariff?: RecursivePartial<ITariff>
  onSubmitSuccess: (tariff: ITariff) => void
  onClose: () => void
}

export const TariffModal = ({ isOpen, tariff, onSubmitSuccess, onClose }: Props) => {
  const toast = useToast()
  const params = useParams() as any
  const { organisation, locations } = useOrganisationContext()
  const [, submitData] = useAxios<RecursivePartial<ITariff>>({
    method: tariff ? 'PUT' : 'POST',
    url: tariff?.id ? `${BFF_PROXY_PATHS.TARIFFS}/${tariff?.id}` : BFF_PROXY_PATHS.TARIFFS,
  })

  const [formData, setFormData] = React.useState<RecursivePartial<ITariff>>()

  const initialValues: RecursivePartial<ITariff> = tariff || {
    rates: [{ id: genUUID() }],
  }

  return (
    <Formik initialValues={initialValues} validateOnChange={false} validationSchema={schema} onSubmit={() => {}}>
      {({ errors, validateForm }) => {
        return (
          <Modal
            style={{ w: '95%', maxWidth: 600 }}
            title={!tariff ? 'New tariff' : 'Edit tariff'}
            body={<TariffForm tariff={tariff} onChange={setFormData} isActive={isOpen} errors={errors} />}
            confirmText={!tariff ? 'Create' : 'Save'}
            variant="accent"
            hasErrors={Object.keys(errors).length > 0}
            cardStyle={{ backgroundColor: 'white' }}
            onConfirm={async () => {
              try {
                const validationErrors = await validateForm(formData)

                if (Object.keys(validationErrors).length > 0) {
                  toast(`Form validation has failed, please complete required fields`, { variant: 'error' })
                  return
                }

                const location = locations?.find((loc) => loc.id === params?.locationId)
                const locationId = location?.id

                const newTariff = await submitData({
                  url: tariff?.id
                    ? `${BFF_PROXY_PATHS.LOCATIONS}/${locationId}/tariffs/${tariff?.id}`
                    : `${BFF_PROXY_PATHS.LOCATIONS}/${locationId}/tariffs`,
                  body: _.omit({ ...formData, organisationId: organisation?.id }, 'location', 'id'),
                })

                newTariff && onSubmitSuccess(newTariff as ITariff)
                toast(`Tariff saved`, { variant: 'success' })
                setFormData(undefined)
                onClose()
              } catch (error) {
                toast(`Something went wrong, please try again.`, { variant: 'error' })
              }
            }}
            onClose={onClose}
            isOpen={isOpen}
          />
        )
      }}
    </Formik>
  )
}
