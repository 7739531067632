import { Anchor } from '@mantine/core'
import { useInterval } from '@mantine/hooks'
import { x } from '@xstyled/styled-components'
import React from 'react'
import { Badge, THeader } from 'src/components'
import { AMPECO_PATHS, BFF_PROXY_PATHS } from 'src/constants/paths'
import { useAxios } from 'src/hooks'

const mapBayStatusToBadge = (status: IBay['status']) => {
  const badgeStyle = { maxWidth: 'unset', w: '120px', px: '10px' }

  if (status === 'available') return <Badge variant="green" style={badgeStyle} text="Available" />
  if (status === 'charging') return <Badge variant="blue" style={badgeStyle} text="Charging" />
  if (status === 'finishing') return <Badge variant="blue" style={badgeStyle} text="Finishing" />

  if (status === 'suspendedEVSE') return <Badge variant="purple" style={badgeStyle} text="Suspended EVSE" />
  if (status === 'suspendedEV') return <Badge variant="purple" style={badgeStyle} text="Suspended EV" />

  if (status === 'preparing') return <Badge variant="yellow" style={badgeStyle} text="Preparing" />

  return <Badge variant="grey" style={badgeStyle} text={status} />
}

export const useBayTableHeaders = (locationId: string) => {
  const [{ data: bays }, refetchBays] = useAxios<IBay[]>({
    url: `${BFF_PROXY_PATHS.LOCATION(locationId)}/bays`,
    method: 'GET',
  })

  const refetchBaysInterval = useInterval(refetchBays, 5000)

  React.useEffect(() => {
    refetchBaysInterval.start()

    return refetchBaysInterval.stop

    // eslint-disable-next-line
  }, [])

  return [
    {
      key: 'name',
      render: (_, bay: IBay) => {
        return (
          <Anchor onClick={() => window.open(AMPECO_PATHS.CHARGE_POINT(bay?.chargePointId), '_blank')}>
            <x.span fontSize="12px" color="dark">
              {bay?.name}
            </x.span>
          </Anchor>
        )
      },
    },
    {
      customKey: 'Charge point ID',
      align: 'center',
      format: (_, bay: IBay) => {
        return (
          <Anchor onClick={() => window.open(AMPECO_PATHS.CHARGE_POINT(bay?.chargePointId), '_blank')}>
            <x.span fontSize="12px" color="dark">
              {bay?.chargePointId}
            </x.span>
          </Anchor>
        )
      },
    },
    {
      customKey: 'EVSE ID',
      align: 'center',
      format: (_, bay: IBay) => {
        return (
          <Anchor onClick={() => window.open(AMPECO_PATHS.EVSE(bay?.evseId), '_blank')}>
            <x.span fontSize="12px" color="dark">
              {bay?.evseId}
            </x.span>
          </Anchor>
        )
      },
    },
    { customKey: 'EVSE network ID', align: 'center', format: (_, { evseNetworkId }) => `${evseNetworkId}` },
    {
      key: 'status',
      align: 'center',
      render: (_, item: IBay) => {
        const bay = bays?.find(({ id }) => id === item.id)
        return mapBayStatusToBadge(bay?.status || '')
      },
    },
  ] as THeader<IBay>[]
}
