import React from 'react'
import { BFF_PROXY_PATHS } from 'src/constants/paths'
import { useAxios } from 'src/hooks'

import { Modal } from '../../../components'

interface Props {
  isOpen: boolean
  organisation: Organisation
  onDelete: (organisation: Partial<Organisation>) => void
  onClose: () => void
}

export const DeleteOrgModal = ({ isOpen, organisation, onDelete, onClose }: Props) => {
  const [, deleteOrganisation] = useAxios<Organisation>({
    method: 'DELETE',
    url: BFF_PROXY_PATHS.ORGANISATIONS + `/${organisation?.id}`,
  })

  const handleDeleteConfirm = React.useCallback(async () => {
    if (!organisation) return
    await deleteOrganisation({ params: { id: organisation.id } })
    onDelete(organisation)
    onClose()
  }, [organisation, deleteOrganisation, onDelete, onClose])

  return (
    <Modal
      isOpen={isOpen}
      cardStyle={{ maxWidth: 450 }}
      title="Are you sure?"
      body={
        <h4>
          The organisation with name <strong>{organisation?.name || 'This item'}</strong> and <strong>ALL</strong>{' '}
          associated resources linked to it will be permanently deleted. This will also delete all sessions, bookings,
          locations, users, bays, transactions, decodes, and devices associated with this organisation. This action is{' '}
          <strong>irreversible</strong>!
        </h4>
      }
      onConfirm={handleDeleteConfirm}
      confirmText="Delete"
      onClose={onClose}
    />
  )
}
