import { Formik } from 'formik'
import _ from 'lodash'
import React from 'react'
import { BFF_PROXY_PATHS } from 'src/constants/paths'
import { useAxios, useToast } from 'src/hooks'

import { Modal } from '../../../components'
import schema from '../Organisation/Organisation.schema'
import OrganisationForm from '../Organisation/OrganisationForm'

interface Props {
  isOpen: boolean
  organisation?: Organisation
  onSubmitSuccess: (organisation: Partial<Organisation>) => void
  onClose: () => void
}

export const OrganisationModal = ({ isOpen, organisation, onSubmitSuccess, onClose }: Props) => {
  const toast = useToast()
  const [, submitData] = useAxios<Partial<Organisation>>({
    method: organisation ? 'PUT' : 'POST',
    url: organisation?.id ? `${BFF_PROXY_PATHS.ORGANISATIONS}/${organisation?.id}` : BFF_PROXY_PATHS.ORGANISATIONS,
  })

  const [formData, setFormData] = React.useState<Partial<Organisation>>()

  const initialValues = organisation || {}

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={() => {}}>
      {({ errors, validateForm }) => {
        return (
          <Modal
            style={{ w: '95%', maxWidth: 700 }}
            title={!organisation ? 'New organisation' : 'Edit organisation'}
            body={
              <OrganisationForm organisation={organisation} onChange={setFormData} isActive={isOpen} errors={errors} />
            }
            confirmText={!organisation ? 'Create' : 'Save'}
            variant="accent"
            hasErrors={Object.keys(errors).length > 0}
            onConfirm={async () => {
              try {
                const validationErrors = await validateForm(formData)

                if (Object.keys(validationErrors).length > 0) {
                  toast(`Form validation has failed, please complete required fields`, { variant: 'error' })
                  return
                }

                const newOrg = await submitData({
                  body: _.omit(formData, 'id', 'permitProvider', 'devices', 'tariffs', 'users', 'locations'),
                })
                newOrg && onSubmitSuccess(newOrg)
                toast(`${formData?.name || 'Organisation'} has been saved`, { variant: 'success' })
                setFormData(undefined)
                onClose()
              } catch (error: any) {
                toast(`Something went wrong, please try again.`, { variant: 'error' })
              }
            }}
            onClose={onClose}
            isOpen={isOpen}
          />
        )
      }}
    </Formik>
  )
}
