import { x } from '@xstyled/styled-components'
import dayjs from 'dayjs'
import moment from 'moment'
import React from 'react'
import { Button, InputText, Label, RowFields, SelectDropdown } from 'src/components'
import { Error } from 'src/components/Error'
import { BFF_PROXY_PATHS } from 'src/constants'
import { useAxios } from 'src/hooks'
import { DateRangePicker, TransactionSource } from 'src/screens/Transactions/components'
import { mapProviderToSource } from 'src/screens/Transactions/components/providerMapper'
import { genQueryParams } from 'src/utils'

interface BookingsFiltersProps {
  initialValues?: BookingFilter
  onUpdate?: (filters: BookingFilter) => void
  onReset?: () => void
  loading?: boolean
}

const MAX_BOOKINGS_RANGE_DAYS = 31

const defaultFilters: BookingFilter = {
  reference: '',
  vrm: '',
  type: undefined,
  source: undefined,
  createdAt: {
    gt: dayjs().subtract(MAX_BOOKINGS_RANGE_DAYS, 'days').startOf('day').toISOString(),
    lt: dayjs().endOf('day').toISOString(),
  },
}

export const BookingsFilters = ({ initialValues = {}, onReset, onUpdate, loading }: BookingsFiltersProps) => {
  const [isReferenceValid, setIsReferenceValid] = React.useState(false)
  const [filters, setFilters] = React.useState<BookingFilter>({
    ...defaultFilters,
    ...initialValues,
  })

  const [{ data: providers }] = useAxios<IProvider[]>({
    url: BFF_PROXY_PATHS.PROVIDERS,
    params: { filter: genQueryParams({ type: 'PAYMENT' }) },
  })

  const resetFilters = () => {
    setFilters(defaultFilters)
    onReset?.()
  }

  const sanitizeReferenceValue = (inputValue) => {
    let sanitized = inputValue.replace(/\D/g, '')

    if (sanitized.length > 10) sanitized = sanitized.substring(0, 10)

    return sanitized
  }

  const sanitizeVrmValue = (inputValue) => {
    let sanitized = inputValue.replace(/[^a-zA-Z0-9]/g, '')

    if (sanitized.length > 8) sanitized = sanitized.substring(0, 8)

    return sanitized
  }

  return (
    <x.div display="flex" p={5} bg="white" borderRadius={5} w="100%">
      <x.div display="flex" flexDirection="column" w="100%">
        <RowFields>
          <x.div display="flex" flexDirection="column" gap="5px">
            <Label>Booking Reference</Label>
            <InputText
              value={filters?.reference}
              placeholder="Full Booking Reference"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const { value } = event.target

                const sanitizedValue = sanitizeReferenceValue(value)

                setFilters({ ...filters, reference: sanitizedValue })

                setIsReferenceValid(sanitizedValue.length === 10)
              }}
            />
            <Error visible={filters?.reference?.length > 0 && !isReferenceValid}>Must have 10 digits</Error>
          </x.div>
          <x.div display="flex" flexDirection="column" gap="5px">
            <Label>VRM</Label>
            <InputText
              value={filters?.vrm}
              placeholder="Full vehicle registration mark"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const { value } = event.target

                setFilters((prevFilters) => ({ ...prevFilters, vrm: sanitizeVrmValue(value).toUpperCase() }))
              }}
            />
          </x.div>
          <SelectDropdown
            label="Booking Type"
            value={filters?.type}
            placeholder="Select type"
            items={[
              { value: 'parking', label: 'Park only' },
              { value: 'charging', label: 'Charge Only' },
              { value: 'park_and_charge', label: 'Park & Charge' },
            ]}
            onSelect={(selectedItems) => {
              const selectedItem = selectedItems?.[0]

              setFilters((prevFilters) => ({ ...prevFilters, type: selectedItem?.value }))
            }}
          />
          <SelectDropdown
            label="Booking source"
            value={filters?.source}
            placeholder="Select source"
            items={providers?.map((provider) => ({
              label: mapProviderToSource(provider.slug),
              value: mapProviderToSource(provider.slug).toUpperCase(),
              labelDisplay: <TransactionSource provider={provider.slug} />,
            }))}
            onSelect={(selectedItems) => {
              const selectedItem = selectedItems?.[0]

              setFilters((prevFilters) => ({ ...prevFilters, source: selectedItem?.value }))
            }}
          />

          <DateRangePicker
            initialValue={filters?.createdAt ? [moment(filters?.createdAt?.gt), moment(filters?.createdAt?.lt)] : null}
            onChange={(range) => {
              const { startDate, endDate } = range || []

              const gt = startDate?.startOf('day').toISOString()
              const lt = endDate?.endOf('day').toISOString()

              setFilters((prevFilters) => ({ ...prevFilters, createdAt: { gt, lt } }))
            }}
            maxRange={MAX_BOOKINGS_RANGE_DAYS}
          />
        </RowFields>

        <x.div display="flex" gap="8px">
          <Button
            variant="primary"
            text="Search"
            disabled={loading || (filters.reference.length > 0 && !isReferenceValid)}
            onClick={() => {
              if (!(filters?.reference?.length > 0) || isReferenceValid) {
                onUpdate?.(filters)
              }
            }}
          />
          <Button variant="destructive" text="Reset all filters" disabled={loading} onClick={resetFilters} />
        </x.div>
      </x.div>
    </x.div>
  )
}
