import { Formik } from 'formik'
import { Modal } from 'src/components'
import { BFF_PROXY_PATHS } from 'src/constants/paths'
import { useAuthContext, useOrganisationContext } from 'src/context'
import { useAxios, useToast } from 'src/hooks'
import { getISOString } from 'src/utils'

import { IPermitSchema, PermitForm, PermitSchema } from '../PermitForm'

import { transformFormDataToPermit, transformPermitToFormData } from './utils'

interface Props {
  isOpen: boolean
  permit?: IPermit
  onSubmitSuccess: (permit: Partial<IPermit>) => void
  onClose: () => void
}

export const PermitModal = ({ isOpen, permit, onSubmitSuccess, onClose }: Props) => {
  const toast = useToast()
  const { organisation, locations } = useOrganisationContext()
  const { user } = useAuthContext()

  const [, submitData] = useAxios<Partial<IPermit>>({
    method: permit ? 'PUT' : 'POST',
    url: permit?.id ? `${BFF_PROXY_PATHS.PERMITS}/${permit.id}` : BFF_PROXY_PATHS.PERMITS,
    runOnInit: false,
  })

  const initialValues = permit ? transformPermitToFormData(permit) : {}

  return (
    <Formik initialValues={initialValues} validationSchema={PermitSchema} onSubmit={() => {}}>
      {({ values, errors, validateForm, resetForm, setValues, setTouched }) => {
        return (
          <Modal
            isOpen={isOpen}
            style={{ w: '95%', maxWidth: 600 }}
            title={!permit ? 'New permit' : 'Edit permit'}
            body={<PermitForm />}
            confirmText={!permit ? 'Create' : 'Save'}
            variant="accent"
            hasErrors={Object.keys(errors).length > 0}
            cardStyle={{ backgroundColor: 'white' }}
            onConfirm={async () => {
              try {
                const validationErrors = await validateForm(values)
                setTouched(validationErrors as any)

                if (Object.keys(validationErrors).length > 0) {
                  toast(`Form validation has failed, please complete required fields`, { variant: 'error' })
                  return
                }

                const permitData = transformFormDataToPermit(values as IPermitSchema)

                const location = locations?.find((loc) => loc.id === permitData?.locationId)

                const startDateISO = getISOString({ date: permitData?.startDate as string, tz: location?.timezone })
                const endDateISO = getISOString({ date: permitData?.endDate as string, tz: location?.timezone })
                const sanitizedVrm = permitData?.vrm?.replace(/ /g, '')

                const newPermit = await submitData({
                  body: {
                    ...permitData,
                    vrm: sanitizedVrm,
                    startDate: startDateISO,
                    endDate: endDateISO,
                    organisationId: organisation?.id,
                    creatorId: user?.id,
                  },
                })
                newPermit && onSubmitSuccess(newPermit)

                toast(`Permit has been saved`, { variant: 'success' })

                onClose()
              } catch (error) {
                toast(`Something went wrong, please try again.`, { variant: 'error' })
              }
            }}
            onOpen={() => {
              setValues(initialValues, false)
            }}
            onClose={() => {
              resetForm()
              onClose()
            }}
          />
        )
      }}
    </Formik>
  )
}
