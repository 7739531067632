import ConnectedIcon from '@mui/icons-material/ConnectedTv'
import { FormikContextType } from 'formik'
import { useSnackbar } from 'notistack'
import { Button } from 'src/components'
import { BFF_PROXY_PATHS } from 'src/constants/paths'
import { useAxios } from 'src/hooks'
import { IDevice } from 'src/typings/device'

interface ToastParams {
  domain?: string
  isSuccess: boolean
}

interface PingDeviceButtonProps {
  formik: FormikContextType<Partial<IDevice>>
}

export const PingDeviceButton = ({ formik }: PingDeviceButtonProps) => {
  const { remoteIp: domain = '', protocol = 'http', remotePort: port = '80' } = formik.values

  const [{ fetching: pinging }, pingDevice] = useAxios({
    url: BFF_PROXY_PATHS.DEVICE_PING(domain, protocol, port),
    runOnInit: false,
  })
  const { enqueueSnackbar } = useSnackbar()

  const dispatchToast = ({ domain, isSuccess }: ToastParams): void => {
    enqueueSnackbar(`Connection to ${domain ?? 'unknown'} has ${isSuccess ? 'been established!' : 'failed!'}`, {
      variant: isSuccess ? 'success' : 'error',
    })
  }

  return (
    <Button
      text="Ping"
      style={{
        h: '40px',
        w: '80px',
        marginRight: 10,
        bg: 'grey.24',
      }}
      variant="secondary"
      type="button"
      icon={<ConnectedIcon fontSize="medium" style={{ marginRight: 5 }} />}
      loading={pinging}
      loaderColor="dark"
      disabled={pinging || formik.isSubmitting || !domain || !protocol || !port}
      onClick={async () => {
        if (domain && protocol && port) {
          try {
            await pingDevice({ timeout: 5000 })
            dispatchToast({ domain, isSuccess: true })
          } catch (error) {
            dispatchToast({ domain, isSuccess: false })
          }
        }
      }}
    />
  )
}
