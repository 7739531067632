import { x } from '@xstyled/styled-components'
import moment from 'moment'
import React from 'react'
import { Button, InputText, Label, MultiSelect, RangePicker, RowFields, SelectDropdown } from 'src/components'
import { BFF_PROXY_PATHS } from 'src/constants'
import { useOrganisationContext } from 'src/context'
import { useAxios } from 'src/hooks'
import { genQueryParams } from 'src/utils'

interface BarrierEventsFiltersProps {
  initialValues?: BarrierEventFilter
  onUpdate?: (filters: BarrierEventFilter) => void
  onReset?: () => void
}

const defaultFilters: BarrierEventFilter = {
  locationId: undefined,
  barrierId: undefined,
  createdAt: undefined,
  released: undefined,
  vrm: '',
}

export const BarrierEventsFilters = ({ initialValues = {}, onReset, onUpdate }: BarrierEventsFiltersProps) => {
  const { organisation } = useOrganisationContext()

  const [filters, setFilters] = React.useState<BarrierEventFilter>({
    ...defaultFilters,
    ...initialValues,
  })

  const [{ data: locations }, refetchLocations] = useAxios<IFullLocation[]>({
    url: `${BFF_PROXY_PATHS.LOCATIONS}`,
    params: {
      filter: genQueryParams({ organisationId: organisation?.id }),
      include: genQueryParams({ barriers: true }),
    },
    pause: !organisation?.id,
  })

  const resetFilters = () => {
    setFilters(defaultFilters)
    onReset?.()
  }

  const hasLocationSelected = filters?.locationId && filters?.locationId?.in?.length > 0

  const barriers = locations?.map((location) => location?.barriers ?? []).flat()

  const barriersByLocation = barriers
    ?.filter((barrier) => !hasLocationSelected || filters?.locationId?.in?.includes(barrier.locationId))
    ?.map((barrier) => ({ value: barrier?.id, label: barrier?.name }))

  React.useEffect(() => {
    refetchLocations()
  }, [organisation?.id])

  return (
    <x.div display="flex" p={5} bg="white" borderRadius={5} w="100%">
      <x.div display="flex" flexDirection="column" w="100%">
        <RowFields>
          <MultiSelect
            label="Locations"
            value={filters?.locationId?.in}
            placeholder="Select locations"
            options={locations?.map((location) => ({ value: location?.id, label: location?.name }))}
            onSelect={(locationIds) => {
              setFilters({ ...filters, locationId: { in: locationIds }, barrierId: undefined })
            }}
          />
          <MultiSelect
            label="Barriers"
            value={filters?.barrierId?.in}
            placeholder="Select barriers"
            options={barriersByLocation}
            onSelect={(barrierIds) => {
              setFilters({ ...filters, barrierId: { in: barrierIds } })
            }}
          />

          <RangePicker
            label="Exit read date"
            value={filters?.createdAt ? [moment(filters?.createdAt?.gt), moment(filters?.createdAt?.lt)] : null}
            allowEmpty={[false, false]}
            allowClear={false}
            onChange={(range) => {
              const [startDate, endDate] = range || []

              const gt = startDate?.startOf('day').toISOString()
              const lt = endDate?.endOf('day').toISOString()

              setFilters({ ...filters, createdAt: { gt, lt } })
            }}
          />

          <SelectDropdown
            label="Release status"
            value={filters?.released}
            placeholder="Select"
            items={[
              { value: true, label: 'Released' },
              { value: false, label: 'Not released' },
            ]}
            onSelect={(selected) => {
              setFilters({ ...filters, released: selected?.[0]?.value })
            }}
          />

          <x.div display="flex" flexDirection="column" gap="5px">
            <Label>VRM</Label>
            <InputText
              value={filters?.vrm?.toUpperCase()}
              placeholder="Full vehicle registration mark"
              onChange={(e) => setFilters({ ...filters, vrm: e.target.value?.toUpperCase() })}
            />
          </x.div>
        </RowFields>

        <x.div display="flex" gap="8px">
          <Button variant="primary" text="Search" onClick={() => onUpdate?.(filters)} />
          <Button variant="destructive" text="Reset all filters" onClick={resetFilters} />
        </x.div>
      </x.div>
    </x.div>
  )
}
