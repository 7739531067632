import { useInterval } from '@mantine/hooks'
import React from 'react'
import { BFF_PROXY_PATHS } from 'src/constants/paths'
import { useAxios } from 'src/hooks'
import { IDeviceSyncStatus, ISyncStatus } from 'src/typings/device'

import { SyncBadge } from './SyncBadge'

interface DeviceSyncStatusProps {
  deviceId: string
  onAutomaticRefetchStateChange?: (isRefetchActive: boolean) => void
  type?: 'config' | 'tariff'
}

export const DeviceSyncStatus = ({
  deviceId,
  onAutomaticRefetchStateChange,
  type = 'config',
}: DeviceSyncStatusProps) => {
  const [{ data }, refetchSyncStatus] = useAxios<IDeviceSyncStatus>({
    url: BFF_PROXY_PATHS.DEVICE_SYNC_STATUS(deviceId),
    runOnInit: !!deviceId,
  })

  const { active: isRefetchActive, start, stop } = useInterval(refetchSyncStatus, 5000)

  const syncStatus = data?.[type] as ISyncStatus

  React.useEffect(() => {
    if (!syncStatus) return

    if (syncStatus === 'IN_SYNC' || syncStatus === 'ABORTED') {
      stop()
    } else {
      start()
    }

    return () => {
      stop()
    }
  }, [syncStatus])

  React.useEffect(() => {
    onAutomaticRefetchStateChange?.(isRefetchActive)
  }, [isRefetchActive])

  const [{ fetching: forcingSync }] = useAxios({
    url: BFF_PROXY_PATHS.DEVICE_FORCE_SYNC(deviceId),
    method: 'POST',
    runOnInit: false,
  })

  if (forcingSync) {
    return <SyncBadge variant="yellow">Syncing...</SyncBadge>
  }

  if (syncStatus === 'IN_SYNC') {
    return <SyncBadge variant="green">In sync</SyncBadge>
  }

  if (syncStatus === 'SYNCING') {
    return <SyncBadge variant="yellow">Syncing...</SyncBadge>
  }

  if (syncStatus === 'PENDING') {
    return <SyncBadge variant="blue">Sync scheduled</SyncBadge>
  }

  if (syncStatus === 'ABORTED') {
    return <SyncBadge variant="red">Sync aborted</SyncBadge>
  }

  return <SyncBadge variant="grey">Loading...</SyncBadge>
}
