import ReactDOM from 'react-dom'

import App from './App'
import { SentryHelper } from './helpers'
import { setupAxiosInterceptor } from './utils'

SentryHelper.init()

setupAxiosInterceptor()

ReactDOM.render(<App />, document.getElementById('root'))
