import React from 'react'
import { IDevice } from 'src/typings/device'
import { IOrganisation } from 'src/typings/organisationV2'
import { genQueryParams } from 'src/utils'

import { BFF_PROXY_PATHS } from '../constants/paths'
import { useAxios, UseAxiosParams, useUpdateEffect } from '../hooks'

import { useAuthContext } from './AuthContext'
import { useClientCacheContext } from './ClientCache'

interface OrganisationContextValue {
  organisation?: Organisation
  organisations?: Organisation[]
  locations?: ILocation[]
  devices?: IDevice[]
  tariffs?: ITariff[]
  fetchingOrgData: boolean
  selectedOrganisationId: Organisation['id'] | null
  setSelectedOrganisationId: (id: Organisation['id']) => void
  refetchOrgData: (params?: Partial<UseAxiosParams<IOrganisation[]>> | undefined) => Promise<Nullable<IOrganisation[]>>
  hasLocations?: boolean
  hasDevices?: boolean
}

export const OrganisationContext = React.createContext<OrganisationContextValue>({} as any)

export const OrganisationProvider = ({ children }: { children: any }): JSX.Element => {
  const { user, isAdmin } = useAuthContext()
  const [selectedOrganisationId, setSelectedOrganisationId] = React.useState<string | null>(
    localStorage.getItem('org.id') || user?.organisationId || null,
  )

  const [{ data: organisations, fetching: fetchingOrgData }, refetchOrgData] = useAxios<Organisation[]>({
    url: BFF_PROXY_PATHS.ORGANISATIONS,
    params: {
      filter: genQueryParams({ id: isAdmin ? undefined : user?.organisationId }),
      include: genQueryParams({
        devices: true,
        locations: { include: { tariffs: true, bays: true, barriers: true, cameras: true } }, // TODO request locations wherever needed
      }),
    },
  })

  const initOrg = organisations?.find((org) => org.id === selectedOrganisationId) || organisations?.[0]
  const [organisation, setOrganisation] = React.useState<Organisation | undefined>(initOrg)

  const { clear: clearCache } = useClientCacheContext()

  React.useEffect(() => {
    if (selectedOrganisationId) return
    if (!user) return

    setSelectedOrganisationId(user?.organisationId)
  }, [user, selectedOrganisationId])

  React.useEffect(() => {
    const selectedOrganisation = organisations?.find((org) => org.id === selectedOrganisationId)
    if (!selectedOrganisation) return

    setOrganisation(selectedOrganisation)
  }, [organisations, selectedOrganisationId])

  useUpdateEffect(() => {
    clearCache()
    refetchOrgData()

    // eslint-disable-next-line
  }, [selectedOrganisationId])

  const locations = organisation?.locations?.sort((a, b) => (a.name < b.name ? -1 : 1)) || []
  const devices = organisation?.devices?.sort((a, b) => (a.name < b.name ? -1 : 1)) || []
  const tariffs = organisation?.tariffs || []

  return (
    <OrganisationContext.Provider
      value={{
        organisation,
        organisations,
        tariffs,
        fetchingOrgData,
        refetchOrgData,
        locations,
        devices,
        selectedOrganisationId,
        setSelectedOrganisationId,
        hasLocations: locations && locations?.length > 0,
        hasDevices: devices && devices?.length > 0,
      }}
    >
      {children}
    </OrganisationContext.Provider>
  )
}

export const OrganisationConsumer = OrganisationContext.Consumer

export const useOrganisationContext = () => React.useContext(OrganisationContext)
