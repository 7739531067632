import React from 'react'
import { Modal } from 'src/components'
import { BFF_PROXY_PATHS } from 'src/constants/paths'
import { useAxios } from 'src/hooks'

interface Props {
  isOpen: boolean
  barrier?: IBarrier
  locationId: string
  onDelete: (barrier: Partial<IBarrier>) => void
  onClose: () => void
}

export const DeleteBarrierModal = ({ isOpen, barrier, locationId, onDelete, onClose }: Props) => {
  const [, deleteBarrier] = useAxios<IBarrier>({
    method: 'DELETE',
    url: `${BFF_PROXY_PATHS.LOCATION_BARRIERS(locationId)}/${barrier?.id}`,
  })

  const handleDeleteConfirm = React.useCallback(async () => {
    if (!barrier) return
    await deleteBarrier()
    onDelete(barrier)
    onClose()
  }, [barrier, deleteBarrier, onDelete, onClose])

  return (
    <Modal
      isOpen={isOpen}
      cardStyle={{ maxWidth: 450 }}
      title="Are you sure?"
      body={
        <h4>
          The barrier with name <strong>{barrier?.name || 'This item'}</strong> will be permanently deleted. This action
          is irreversible!
        </h4>
      }
      onConfirm={handleDeleteConfirm}
      confirmText="Delete"
      onClose={onClose}
    />
  )
}
