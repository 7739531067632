import * as yup from 'yup'

export const LocationConfigsSchema = yup.object().shape({
  configs: yup
    .object()
    .shape({
      defaultPaymentCollectionType: yup.string(),
      supportsEVCharging: yup.boolean(),
      supportsRegularParking: yup.boolean(),
      maxStandardParkingMinutes: yup.number(),
      extensionGracePeriodMinutes: yup.number(),
      shouldUseDecodeImages: yup.boolean(),
      supportsChargeOnly: yup.boolean(),
      helplineNumber: yup.string().nullable().required('Helpline number is required'),
    })
    .nullable(),
  evConfigs: yup
    .object()
    .shape({
      providerId: yup.string().optional().nullable(),
      maxChargingMinutes: yup.number(),
      supportsPremiumParking: yup.boolean(),
      maxPremiumParkingMinutes: yup.number().nullable().optional(),
      maxChargingPower: yup.number(),
    })
    .transform((_, originalObject) => {
      if (originalObject.maxPremiumParkingMinutes === '' || originalObject.maxPremiumParkingMinutes === undefined) {
        return {
          ...originalObject,
          maxPremiumParkingMinutes: null,
        }
      }
      return originalObject
    })
    .nullable(),
  enforcementConfigs: yup.object().shape({
    providerLocationCode: yup.string().nullable().optional(),
    permitsCreationFrequency: yup.string().oneOf(['HOURLY', 'IMMEDIATE', 'NEVER']).nullable().optional(),
  }),
})
