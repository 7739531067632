import React from 'react'
import { useParams } from 'react-router-dom'
import { Modal } from 'src/components'
import { BFF_PROXY_PATHS } from 'src/constants/paths'
import { useAxios } from 'src/hooks'

interface Props {
  isOpen: boolean
  bay: IBay
  onDelete: (bay: Partial<IBay>) => void
  onClose: () => void
}

export const DeleteBayModal = ({ isOpen, bay, onDelete, onClose }: Props) => {
  const params = useParams() as any
  const locationId = params?.locationId

  const [, deleteBay] = useAxios<IBay>({
    method: 'DELETE',
    url: `${BFF_PROXY_PATHS.LOCATION(locationId)}/bays/${bay?.id}`,
  })

  const handleDeleteConfirm = React.useCallback(async () => {
    if (!bay) return
    await deleteBay()
    onDelete(bay)
    onClose()
  }, [bay, deleteBay, onDelete, onClose])

  return (
    <Modal
      isOpen={isOpen}
      cardStyle={{ maxWidth: 450 }}
      title="Are you sure?"
      body={
        <h4>
          The bay with name <strong>{bay?.name || 'This item'}</strong> will be permanently deleted. This action is
          irreversible!
        </h4>
      }
      onConfirm={handleDeleteConfirm}
      confirmText="Delete"
      onClose={onClose}
    />
  )
}
