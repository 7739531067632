import axios from 'axios'

export const getCookie = (name: string): string | null => {
  const cookies = document.cookie.split(';').reduce((acc, cookie) => {
    const [key, value] = cookie.trim().split('=')
    acc[key] = value
    return acc
  }, {})

  return cookies[name]
}

export const setupAxiosInterceptor = () => {
  axios.interceptors.request.use((config) => {
    config.headers = { ...config.headers, 'X-CSRF-Token': getCookie('csrf-token') }

    return config
  })
}
