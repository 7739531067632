import axios from 'axios'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { BFF_PATHS, FE_PATHS } from 'src/constants/paths'
import { SentryHelper } from 'src/helpers'
import { getItem } from 'src/utils'
interface AuthProviderResult {
  isAdmin: boolean | undefined
  isManager: boolean
  isAtLeastManager: boolean
  user: Undefinable<LoggedInUser>
  login: (user: LoggedInUser, onSuccess: (user: LoggedInUser) => void) => void
  logout: () => void
}

const AuthContext = React.createContext<AuthProviderResult>({} as any)

interface AuthProviderProps {
  children: Children
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = React.useState<Undefinable<LoggedInUser>>(() => getItem('user'))

  const isAdmin = user?.type === 'ADMIN'
  const isManager = user?.type === 'MANAGER'
  const isAtLeastManager = isAdmin || isManager

  const navigate = useNavigate()
  const location = useLocation()

  const login = (user: LoggedInUser, onSuccess?: (user: LoggedInUser) => void) => {
    if (!user) return

    localStorage.setItem('user', JSON.stringify(user))
    setUser(user)
    onSuccess?.(user)
  }

  const logout = async () => {
    await axios.post(BFF_PATHS.LOGOUT)

    setUser(undefined)
    localStorage.clear()
  }

  React.useLayoutEffect(() => {
    const verifyToken = async () => {
      try {
        const response = await axios.post(BFF_PATHS.TOKEN)
        const authenticatedUser = response.data?.user
        login(authenticatedUser)

        if (location.pathname === '/' || location.pathname === '') {
          navigate(authenticatedUser?.type === 'ADMIN' ? FE_PATHS.DASHBOARD : FE_PATHS.LOCATIONS)
        }
      } catch (e) {
        await logout()
      }
    }

    verifyToken()
  }, [location])

  React.useEffect(() => {
    if (!user) return

    SentryHelper.setTags({
      id: user.id,
      email: user.email,
      name: user.name,
      role: user.type,
      organisationId: user.organisationId,
    })
  }, [user])

  const value: AuthProviderResult = React.useMemo(
    () => ({ user, login, logout, isAdmin, isManager, isAtLeastManager }),
    [user, login, logout, isAdmin, isManager, isAtLeastManager],
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuthContext = () => React.useContext(AuthContext)
