import { Formik } from 'formik'
import _ from 'lodash'
import React from 'react'
import { BFF_PROXY_PATHS } from 'src/constants/paths'
import { useOrganisationContext } from 'src/context'
import { useAxios, useToast } from 'src/hooks'

import { Modal } from '../../../components'
import schema from '../Location/Location.schema'
import LocationForm from '../Location/LocationForm'

interface Props {
  isOpen: boolean
  location?: Partial<ILocation>
  onSubmitSuccess: (location: ILocation) => void
  onClose: () => void
}

export const LocationModal = ({ isOpen, location, onSubmitSuccess, onClose }: Props) => {
  const { selectedOrganisationId } = useOrganisationContext()
  const toast = useToast()
  const [, submitData] = useAxios<Partial<ILocation>>({
    method: location?.name ? 'PUT' : 'POST',
    url: location?.id ? `${BFF_PROXY_PATHS.LOCATIONS}/${location?.id}` : BFF_PROXY_PATHS.LOCATIONS,
  })

  const [formData, setFormData] = React.useState<Partial<ILocation>>()

  const initialValues = location || {}

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={() => {}}>
      {({ errors, validateForm, setTouched }) => {
        return (
          <Modal
            style={{ w: '95%', maxWidth: 600 }}
            title={!location ? 'New location' : 'Edit location'}
            body={<LocationForm location={location} onChange={setFormData} isActive={isOpen} />}
            confirmText={!location ? 'Create' : 'Save'}
            variant="accent"
            hasErrors={Object.keys(errors).length > 0}
            onConfirm={async () => {
              try {
                const validationErrors = await validateForm(formData)

                setTouched(validationErrors as any)

                if (Object.keys(validationErrors).length > 0) {
                  toast(`Form validation has failed, please complete required fields`, { variant: 'error' })
                  return
                }

                const newLocation = await submitData({
                  body: { ..._.omit(formData, 'id'), organisationId: selectedOrganisationId },
                })
                newLocation && onSubmitSuccess(newLocation as ILocation)
                toast(`${formData?.name || 'Location'} has been saved`, { variant: 'success' })
                setFormData(undefined)
                onClose()
              } catch (error) {
                toast(`Something went wrong, please try again.`, { variant: 'error' })
              }
            }}
            onClose={onClose}
            isOpen={isOpen}
          />
        )
      }}
    </Formik>
  )
}
