import { useDidUpdate } from '@mantine/hooks'
import React from 'react'
import { BFF_PROXY_PATHS } from 'src/constants/paths'
import { useOrganisationContext } from 'src/context'
import { useAxios, UseAxiosData } from 'src/hooks'
import { clearEmpties, genQueryParams } from 'src/utils'

export const useTransactions = ({
  filters,
  searchTerm,
  page = 0,
  limit = 20,
}: {
  searchTerm?: string
  filters?: TransactionFilter
  type?: any
  page?: number
  limit?: number
}): [UseAxiosData<IFullTransaction[]>, () => Promise<void>] => {
  const { organisation, locations } = useOrganisationContext()
  const [transactions, setTransactions] = React.useState<IFullTransaction[] | undefined>([])
  const [hasMore, setHasMore] = React.useState<boolean>(true)
  const [fetching, setFetching] = React.useState<boolean>(false)

  const [, fetchTransactions] = useAxios<Paginated<IFullTransaction>>({
    url: BFF_PROXY_PATHS.TRANSACTIONS,
    runOnInit: false,
  })

  React.useEffect(() => {
    handleFetchTransactions()
    // eslint-disable-next-line
  }, [])

  useDidUpdate(() => handleFetchTransactions(), [page, limit, filters, locations, searchTerm])

  const handleFetchTransactions = React.useCallback(async () => {
    if (!organisation) return

    setFetching(true)

    const orgId = filters?.organisationId ?? organisation?.id

    const sanitizedFilters = clearEmpties(filters)

    const filterQueryParams = genQueryParams({
      ...sanitizedFilters,
      organisationId: orgId,
      reference: searchTerm,
    })

    const response = await fetchTransactions({
      params: {
        page,
        limit,
        filter: filterQueryParams,
        include: genQueryParams({ booking: { include: { location: true } }, provider: true }),
      },
    })

    setFetching(false)

    if (!response) return

    if (page === 0) {
      setTransactions(response?.results || [])
      setHasMore(response?.hasMore || false)
      return
    }

    setTransactions([...(transactions || []), ...(response?.results || [])])
    setHasMore(response?.hasMore || false)

    // eslint-disable-next-line
  }, [page, limit, locations, filters, searchTerm])

  return [{ data: transactions, fetching, hasMore, setData: setTransactions }, handleFetchTransactions]
}
