import { useDidUpdate } from '@mantine/hooks'
import React from 'react'
import { BFF_PROXY_PATHS } from 'src/constants/paths'
import { useOrganisationContext } from 'src/context'
import { useAxios, UseAxiosData } from 'src/hooks'
import { clearEmpties, genQueryParams } from 'src/utils'

export const useBarrierEvents = ({
  filters,
  page = 0,
  limit = 20,
}: {
  filters?: BarrierEventFilter
  type?: any
  page?: number
  limit?: number
}): [UseAxiosData<IBarrierEvent[]>, () => Promise<void>] => {
  const { locations } = useOrganisationContext()
  const [barrierEvents, setBarrierEvents] = React.useState<IBarrierEvent[] | undefined>([])
  const [hasMore, setHasMore] = React.useState<boolean>(true)
  const [fetching, setFetching] = React.useState<boolean>(false)

  const [, fetchBarrierEvents] = useAxios<Paginated<IBarrierEvent>>({
    url: BFF_PROXY_PATHS.BARRIER_LOGS,
    runOnInit: false,
  })

  const handleFetchBarrierEvents = React.useCallback(async () => {
    if (!locations || locations.length === 0) return

    setFetching(true)

    const hasLocationsFilter = filters?.locationId?.in?.length > 0

    const locationIds = hasLocationsFilter ? filters?.locationId?.in : locations?.map((l) => l.id)

    const sanitizedFilters = clearEmpties(filters)

    const filterQueryParams = genQueryParams({
      ...sanitizedFilters,
      locationId: { in: locationIds },
    })

    const response = await fetchBarrierEvents({
      params: {
        page,
        limit,
        filter: filterQueryParams,
        include: genQueryParams({ barrier: true }),
      },
    })

    setFetching(false)

    if (!response) return

    if (page === 0) {
      setBarrierEvents(response?.results || [])
      setHasMore(response?.hasMore || false)
      return
    }

    setBarrierEvents([...(barrierEvents || []), ...(response?.results || [])])
    setHasMore(response?.hasMore || false)

    // eslint-disable-next-line
  }, [page, limit, locations, filters])

  useDidUpdate(() => handleFetchBarrierEvents(), [handleFetchBarrierEvents])

  return [{ data: barrierEvents, fetching, hasMore, setData: setBarrierEvents }, handleFetchBarrierEvents]
}
