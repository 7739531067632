import { Tabs } from '@mantine/core'
import dayjs from 'dayjs'
import React from 'react'
import { Condition, ExportCsvButton, Row } from 'src/components'
import { BFF_PROXY_PATHS } from 'src/constants/paths'
import { Page, Shell } from 'src/containers'
import { useOrganisationContext } from 'src/context'
import { useAxios, useToast } from 'src/hooks'
import { clearEmpties, genQueryParams } from 'src/utils'
import * as TablerIcons from 'tabler-icons-react'

import { TransactionFilters } from '../Transactions/components'
import { MAX_METRICS_RANGE_DAYS } from '../Transactions/transactions.constants'

import { GroupBy, MetricsTable } from './components'
import { useTransactionMetrics } from './hooks'

const initialFilters: TransactionFilter = {
  timestamp: {
    gt: dayjs().subtract(7, 'days').startOf('day').toISOString(),
    lt: dayjs().endOf('day').toISOString(),
  },
  status: 'SUCCESSFUL',
  type: { in: ['CHARGE', 'CAPTURE'] },
}

const getFilterTypes = (groupBy: GroupBy) => {
  if (groupBy === 'location') {
    return ['organisationId', 'locationId', 'deviceId', 'type', 'providerId', 'status', 'timestamp']
  }

  if (groupBy === 'date') {
    return ['organisationId', 'deviceId', 'type', 'providerId', 'status', 'timestamp']
  }

  return []
}

export const Metrics = () => {
  const toast = useToast()
  const { organisation } = useOrganisationContext()

  const [groupBy, setGroupBy] = React.useState<GroupBy>('location')
  const [filters, setFilters] = React.useState<TransactionFilter>(initialFilters)

  const [{ data: metrics, fetching: fetchingMetrics }] = useTransactionMetrics(
    filters,
    groupBy,
    filters?.organisationId ?? organisation?.id,
  )

  const [{ fetching: fetchingCSV }, fetchMetricsCSV] = useAxios<any>({
    url: BFF_PROXY_PATHS.TRANSACTIONS_METRICS(filters?.organisationId ?? organisation?.id),
    params: {
      ...(filters && { filter: genQueryParams({ ...clearEmpties({ ...filters, organisationId: null }) }) }),
      groupBy,
      format: 'csv',
    },
    runOnInit: false,
  })

  return (
    <Page
      loading={!organisation || fetchingCSV}
      endSlot={
        <Row>
          <ExportCsvButton
            filters={filters}
            onClick={fetchMetricsCSV}
            text="Export Metrics"
            disabled={fetchingCSV || fetchingMetrics || !metrics?.length}
            organisationId={filters?.organisationId ?? organisation?.id}
            filename={`Transactions by ${groupBy}`}
          />
          <TransactionFilters
            initialValues={initialFilters}
            maxDaysRange={MAX_METRICS_RANGE_DAYS}
            filterSet={getFilterTypes(groupBy) as TransactionFilterTypes[]}
            onUpdate={(_, updatedFilters) => {
              setFilters(updatedFilters)
              toast('Filters applied', { variant: 'success' })
            }}
            onReset={() => toast('Filters cleared', { variant: 'default' })}
          />
        </Row>
      }
    >
      <Shell className="MetricsTableShell">
        <Condition when={!!organisation}>
          <Tabs
            variant="pills"
            onTabChange={(_tabIndex, tabKey: GroupBy) => {
              setFilters(initialFilters)
              setGroupBy(tabKey)
            }}
          >
            <Tabs.Tab tabKey="location" label="By Location" icon={<TablerIcons.Location size={14} />}>
              <MetricsTable
                metrics={metrics}
                fetchingMetrics={fetchingMetrics}
                groupBy={groupBy}
                timestamp={filters?.timestamp}
              />
            </Tabs.Tab>
            <Tabs.Tab tabKey="date" label="By Date" icon={<TablerIcons.CalendarEvent size={14} />}>
              <MetricsTable
                metrics={metrics}
                fetchingMetrics={fetchingMetrics}
                groupBy={groupBy}
                timestamp={filters?.timestamp}
              />
            </Tabs.Tab>
          </Tabs>
        </Condition>
        <br />
      </Shell>
    </Page>
  )
}
