import { Formik } from 'formik'
import _ from 'lodash'
import React from 'react'
import { useParams } from 'react-router-dom'
import { Modal } from 'src/components'
import { BFF_PROXY_PATHS } from 'src/constants/paths'
import { useAxios, useToast } from 'src/hooks'

import schema from './Bay.schema'
import BayForm from './BayForm'

interface Props {
  isOpen: boolean
  bay?: RecursivePartial<IBay>
  onSubmitSuccess: (bay: IBay) => void
  onClose: () => void
}

export const BayModal = ({ isOpen, bay, onSubmitSuccess, onClose }: Props) => {
  const toast = useToast()
  const params = useParams() as any

  const locationId = params?.locationId

  const [, submitData] = useAxios<RecursivePartial<IBay>>({})

  const [formData, setFormData] = React.useState<RecursivePartial<IBay>>()

  const initialValues: RecursivePartial<IBay> = bay || {
    providerId: '6292827ad1017c048bc327c6', // !Warning hardcoded providerId
  }

  return (
    <Formik initialValues={initialValues} validateOnChange={true} validationSchema={schema} onSubmit={() => {}}>
      {({ errors, validateForm, setTouched }) => {
        return (
          <Modal
            title={!bay ? 'New bay' : 'Edit bay'}
            body={<BayForm bay={initialValues} onChange={setFormData} isActive={isOpen} errors={errors} />}
            confirmText={!bay ? 'Create' : 'Save'}
            variant="accent"
            hasErrors={Object.keys(errors).length > 0}
            cardStyle={{ backgroundColor: 'white', maxWidth: '340px' }}
            onConfirm={async () => {
              try {
                const validationErrors = await validateForm(formData)
                setTouched(validationErrors as any)

                if (Object.keys(validationErrors).length > 0) {
                  toast(`Form validation has failed, please complete required fields`, { variant: 'error' })
                  return
                }

                const newBay = await submitData({
                  url: bay?.id
                    ? `${BFF_PROXY_PATHS.LOCATIONS}/${locationId}/bays/${bay?.id}`
                    : `${BFF_PROXY_PATHS.LOCATIONS}/${locationId}/bays`,
                  method: bay?.id ? 'PUT' : 'POST',
                  body: _.omit({ ...formData }, 'location', 'id'),
                })

                newBay && onSubmitSuccess(newBay as IBay)
                toast(`Bay saved`, { variant: 'success' })
                setFormData(undefined)
                onClose()
              } catch (error) {
                toast(`Something went wrong, please try again.`, { variant: 'error' })
              }
            }}
            onClose={onClose}
            isOpen={isOpen}
          />
        )
      }}
    </Formik>
  )
}
