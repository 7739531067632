import Typography from '@mui/material/Typography'
import { AxiosError } from 'axios'
import { Form, Formik } from 'formik'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, InputField, InputFieldProps } from 'src/components'
import { BFF_PROXY_PATHS, FE_PATHS } from 'src/constants'
import { useAxios, useToast } from 'src/hooks'

import { $RainbowContainer } from '../Login/Login.styled'

import { validationSchema } from './ResetPassword.schema'
import { styles } from './ResetPassword.styles'

const ResetPasswordInputField = (props: InputFieldProps<Partial<User>>) => InputField<Partial<User>>(props)

const initialValues = { newPassword: '', confirmNewPassword: '' }

export const ResetPassword = () => {
  const toast = useToast()
  const { search } = useLocation()
  const navigate = useNavigate()
  const { container, title, form } = styles()

  const [{ error }, requestPasswordReset] = useAxios({
    method: 'POST',
    url: BFF_PROXY_PATHS.RESET_PASSWORD,
  })

  const token = new URLSearchParams(search).get('token')

  const handleSubmit = async (values: typeof initialValues, { resetForm }) => {
    try {
      await requestPasswordReset({ body: { ...values, token } })
      toast('Password reset successful. You can now log in with your new password', {
        variant: 'success',
        autoHideDuration: 10000,
      })
      resetForm()
      navigate(FE_PATHS.LOGIN)
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError && error.response?.data.message === 'Invalid token') {
        toast('The reset token was invalid or expired. Please request a new reset link.', {
          variant: 'error',
          autoHideDuration: 10000,
        })
        navigate(FE_PATHS.FORGOT_PASSWORD)
      } else {
        toast(
          'We couldn’t process your request. If you think this is a mistake, please retry or request a new reset link.',
          { variant: 'error' },
        )
      }
    }
  }

  return (
    <div className={container}>
      <$RainbowContainer />
      <Typography component="h1" variant="h5" className={title}>
        Reset your password
      </Typography>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form className={form}>
            {error && <Typography>{error}</Typography>}
            <ResetPasswordInputField
              placeholder="New password"
              label="New password"
              type="password"
              name="newPassword"
              labelProps={{ color: 'grey82', fontWeight: 300, fontSize: 14 }}
            />
            <ResetPasswordInputField
              placeholder="Confirm new password"
              label="Confirm new password"
              type="password"
              name="confirmNewPassword"
              labelProps={{ color: 'grey82', fontWeight: 300, fontSize: 14 }}
            />
            <Button
              type="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
              text="Save new password"
              style={{ padding: '16px' }}
            />
          </Form>
        )}
      </Formik>
    </div>
  )
}
