import { Form, useFormikContext } from 'formik'
import React from 'react'
import { InputField, SelectDropdown } from 'src/components'
import { BFF_PROXY_PATHS } from 'src/constants'
import { useAxios } from 'src/hooks'

interface BayFormProps {
  onChange?: (data: RecursivePartial<IBay>) => void
  bay?: RecursivePartial<IBay>
  isActive?: boolean
  errors?: any
}

export default function BayForm({ bay, isActive, onChange }: BayFormProps) {
  const formik = useFormikContext<RecursivePartial<IBay>>()
  const [selectedChargePoint, setSelectedChargePoint] = React.useState<any>()
  const [selectedEvse, setSelectedEvse] = React.useState<any>()

  const [{ data: cpsData }] = useAxios({ url: BFF_PROXY_PATHS.CHARGE_POINTS })
  const chargePoints = React.useMemo(() => cpsData || ([] as any), [cpsData])

  React.useEffect(() => {
    if (bay?.chargePointId && bay?.evseId) {
      const chargePoint = chargePoints?.find((cp) => cp.id === bay.chargePointId)
      const evse = chargePoint?.evses.find((e) => e.id === bay.evseId)
      setSelectedChargePoint(chargePoint)
      setSelectedEvse(evse)
    }
  }, [chargePoints, bay])

  React.useEffect(() => {
    formik?.resetForm()
    formik.setValues(bay ?? {}, false)

    // eslint-disable-next-line
  }, [isActive])

  React.useEffect(() => {
    onChange?.(formik.values)
    // eslint-disable-next-line
  }, [formik.values])

  const errors = formik.errors as any

  return (
    <Form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
      <div>
        {isActive && <InputField autoFocus placeholderName="bay" name="name" label="Name" error={errors.name} />}
      </div>
      <br />
      <SelectDropdown
        placeholder="Select the provider charge point"
        label="Charge point"
        error={errors?.chargePointId}
        items={chargePoints?.map((chargePoint) => ({ label: chargePoint.name, value: chargePoint.id }))}
        value={selectedChargePoint?.id}
        onSelect={(selection) => {
          const [selected] = selection || []
          const selectedCp = chargePoints?.find((cp) => cp.id === selected?.value)
          formik.setFieldValue('chargePointId', selectedCp?.id)
          onChange?.({ ...formik.values, chargePointId: selectedCp?.id })
          setSelectedChargePoint(selectedCp)
        }}
      />
      <br />
      <SelectDropdown
        placeholder="Select the provider evse"
        label="Evse"
        error={errors?.evseId || errors?.evseNetworkId}
        items={selectedChargePoint?.evses?.map((evse) => ({ label: evse.id, value: evse.id }))}
        value={selectedEvse?.id}
        onSelect={(selection) => {
          const [selected] = selection || []
          const selectedEvse = selectedChargePoint?.evses.find((evse) => evse.id === selected?.value)
          const evseIndex = selectedChargePoint?.evses.findIndex((evse) => evse.id === selected?.value)
          const evseNetworkId = evseIndex + 1

          formik.setValues({ ...formik.values, evseId: selectedEvse?.id, evseNetworkId }, true)

          onChange?.({ ...formik.values, evseId: selectedEvse?.id, evseNetworkId })
          setSelectedEvse(selectedEvse)
        }}
      />
    </Form>
  )
}
