import UserAccountIcon from '@mui/icons-material/AccountBox'
import React from 'react'
import { Badge, Button, EmptyState, Table } from 'src/components'
import { BFF_PROXY_PATHS } from 'src/constants'
import { Page, Shell } from 'src/containers'
import { useOrganisationContext } from 'src/context'
import { useAxios } from 'src/hooks'
import { genQueryParams, sortBy } from 'src/utils'

import { DeleteUserModal, UserModal } from './Modals'

export function Users() {
  const { fetchingOrgData, refetchOrgData, organisation } = useOrganisationContext()

  const [{ data: users = [], fetching: fetchingUsers }, fetchUsers] = useAxios<User[]>({
    url: BFF_PROXY_PATHS.USERS,
    params: {
      filter: genQueryParams({ organisationId: organisation?.id }),
    },
    runOnInit: false,
  })

  const [delUserModalOpen, setDelUserModalOpen] = React.useState<boolean>(false)
  const [userModalOpen, setUserModalOpen] = React.useState<boolean>(false)

  const [userToEdit, setUserToEdit] = React.useState<User>()
  const [userToDelete, setUserToDelete] = React.useState<User>()

  const [searchUsers, setSearchUsers] = React.useState<User[]>()

  const handleSearch = (searchTerm: string) => {
    if (searchTerm) {
      setSearchUsers(
        users?.filter(
          ({ name, email }) =>
            email?.toLowerCase().startsWith(searchTerm?.toLowerCase()) ||
            name?.toLowerCase().startsWith(searchTerm?.toLowerCase()),
        ),
      )
    } else {
      setSearchUsers(undefined)
    }
  }

  React.useEffect(() => {
    if (!organisation?.id) return

    fetchUsers()
  }, [organisation?.id])

  return (
    <Page
      searchPlaceholder="Search for users (name or email)"
      onSearch={handleSearch}
      endSlot={<Button text="+ Add" onClick={() => setUserModalOpen(true)} variant="accent" />}
      loading={fetchingOrgData || fetchingUsers}
    >
      <Shell title="Users" subtitle="Manage users and their permissions" loading={!users && fetchingOrgData}>
        {!fetchingOrgData && !fetchingUsers && users && users.length === 0 && (
          <EmptyState
            title="There are no users currently set up"
            subtitle="Start by adding a new user. Their account will then appear here."
            icon={<UserAccountIcon />}
            buttonProps={{
              text: 'Create user',
              variant: 'accent',
              onClick: () => setUserModalOpen(true),
              style: { h: '50px' },
            }}
          />
        )}
        {users && users.length > 0 && (
          <Table
            items={(searchUsers || [...users]).sort(sortBy('email', 'ASC'))}
            headers={[
              { key: 'name', type: 'string' },
              { key: 'email', type: 'string' },
              {
                key: 'type',
                type: 'string',
                align: 'center',
                render: (_, user?: User) => (
                  <Badge
                    text={user?.type?.toUpperCase() as string}
                    variant={['ADMIN', 'MANAGER'].includes(user?.type ?? '') ? 'blue' : 'yellow'}
                  />
                ),
              },
            ]}
            actions={[
              {
                label: 'Edit',
                onClick: (item) => {
                  setUserToEdit(item)
                  setUserModalOpen(true)
                },
                type: 'edit',
              },
              {
                label: 'Delete',
                onClick: (item) => {
                  setUserToDelete(item)
                  setDelUserModalOpen(true)
                },
                type: 'delete',
              },
            ]}
          />
        )}
        <UserModal
          isOpen={userModalOpen}
          user={userToEdit}
          onSubmitSuccess={async () => {
            refetchOrgData()
            setUserToEdit(undefined)
            await fetchUsers()
          }}
          onClose={() => {
            setUserModalOpen(false)
            setUserToEdit(undefined)
          }}
        />
        <DeleteUserModal
          isOpen={delUserModalOpen}
          onClose={() => setDelUserModalOpen(false)}
          user={userToDelete as User}
          onDelete={async () => {
            refetchOrgData()

            await fetchUsers()
          }}
        />
      </Shell>
    </Page>
  )
}
