import NoDevicesForSelectionIcon from '@mui/icons-material/Devices'
import React from 'react'
import { Condition, EmptyState } from 'src/components'
import { BFF_PROXY_PATHS } from 'src/constants/paths'
import { Page, Shell } from 'src/containers'
import { useOrganisationContext } from 'src/context'
import { useAxios } from 'src/hooks'
import { IDevice, IDeviceHeartbeat } from 'src/typings/device'

import { DeviceHealthFilter, DevicesHealthTable } from './components'
import { HealthFilter } from './types'
import { getDevicesByHealth } from './utils'

export function Dashboard() {
  const { devices = [], organisation } = useOrganisationContext()
  const deviceIds = devices.map((d) => d.id)

  const [searchTerm, setSearchTerm] = React.useState<string>('')
  const [filteredDevices, setFilteredDevices] = React.useState<IDevice[]>([])

  const [deviceHealthFilter, setDeviceHealthFilter] = React.useState<HealthFilter>()

  const [{ data: heartbeats, fetching: fetchingHeartbeats }, refetchHeartbeats] = useAxios<IDeviceHeartbeat[]>({
    url: BFF_PROXY_PATHS.DEVICES_HEARTBEATS,
    params: { deviceIds },
    runOnInit: false,
  })

  React.useEffect(() => {
    if (!organisation?.id) return
    if (deviceIds.length === 0) return

    refetchHeartbeats({ params: { deviceIds } })
  }, [organisation?.id])

  React.useEffect(() => {
    if (searchTerm.length > 0) {
      const devicesBySearchedName = devices?.filter((d) => d.name.toLowerCase().includes(searchTerm.toLowerCase()))
      const devicesByHealth = getDevicesByHealth(devicesBySearchedName, heartbeats, deviceHealthFilter)
      setFilteredDevices(devicesByHealth)

      return
    }

    const devicesByHealth = getDevicesByHealth(devices, heartbeats, deviceHealthFilter)
    setFilteredDevices(devicesByHealth)
  }, [searchTerm, devices, heartbeats, deviceHealthFilter])

  return (
    <Page
      searchValue={searchTerm}
      onSearch={(value) => setSearchTerm(value)}
      searchPlaceholder="Search for devices (by name)"
      loading={fetchingHeartbeats}
      endSlot={
        <Condition when={!!organisation?.id && !fetchingHeartbeats}>
          <DeviceHealthFilter heartbeats={heartbeats} value={deviceHealthFilter} onChange={setDeviceHealthFilter} />
        </Condition>
      }
    >
      <Shell title="Dashboard" subtitle="Live system health and remote control panel" pt="30px" loading={false}>
        <Condition when={!!organisation?.id && !fetchingHeartbeats}>
          <Condition when={devices.length === 0}>
            <EmptyState
              icon={<NoDevicesForSelectionIcon />}
              title="No devices found"
              subtitle="There are currently no devices setup in your organisation. To monitor the health of devices, please ask an adminstrator to configure them."
            />
          </Condition>
          <Condition when={filteredDevices.length > 0}>
            <DevicesHealthTable devices={filteredDevices} heartbeats={heartbeats} />
          </Condition>
          <Condition when={devices.length > 0 && filteredDevices.length === 0}>
            <EmptyState
              title="No devices found"
              subtitle={'Please refine your search or reset the filters.'}
              buttonProps={{
                text: 'Reset filters',
                onClick: () => {
                  setDeviceHealthFilter(undefined)
                  setSearchTerm('')
                },
                variant: 'secondary',
              }}
              icon={<NoDevicesForSelectionIcon />}
            />
          </Condition>
        </Condition>
      </Shell>
    </Page>
  )
}
