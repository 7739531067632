import React from 'react'
import { Modal } from 'src/components'
import { BFF_PROXY_PATHS } from 'src/constants/paths'
import { useAxios } from 'src/hooks'

interface Props {
  isOpen: boolean
  user: User
  onDelete: (user: Partial<User>) => void
  onClose: () => void
}

export const DeleteUserModal = ({ isOpen, user, onDelete, onClose }: Props) => {
  const [, deleteUser] = useAxios<User>({
    method: 'DELETE',
    url: `${BFF_PROXY_PATHS.USERS}/${user?.id}`,
    runOnInit: false,
  })

  const handleDeleteConfirm = React.useCallback(async () => {
    if (!user) return
    await deleteUser({ params: { id: user.id } })

    onDelete(user)
    onClose()
  }, [user, deleteUser, onDelete, onClose])

  return (
    <Modal
      isOpen={isOpen}
      cardStyle={{ maxWidth: 450 }}
      title="Are you sure?"
      body={
        <h4>
          The user with name <strong>{user?.name || 'This item'}</strong> will be permanently deleted. This action is
          irreversible!
        </h4>
      }
      onConfirm={handleDeleteConfirm}
      confirmText="Delete"
      onClose={onClose}
    />
  )
}
