import { DeviceAction, Protocol } from 'src/typings/device'

export const FE_PATHS = {
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/password-reset',
  LOGIN: '/login',
  BOOKING: (bookingId: string) => `/bookings/${bookingId}`,
  BOOKINGS: '/bookings',
  BARRIERS: '/barriers',
  BARRIER_EVENTS: '/barrier-events',
  DASHBOARD: '/dashboard',
  DEVICES: '/devices',
  DEVICE_ADD: '/devices/new',
  DEVICE_EDIT: '/devices/edit',
  LOCATIONS: '/locations',
  LOCATION: (locationId: string) => `/locations/${locationId}`,
  LOCATION_ADD: '/locations/add',
  LOCATION_EDIT: '/locations/edit',
  ORGANISATIONS: '/organisations',
  ORGANISATION_ADD: '/organisations/add',
  ORGANISATION_EDIT: '/organisations/edit',
  ORGANISATION_VIEW: '/organisations/view',
  TARIFFS: '/tariffs',
  TARIFF_ADD: '/tariffs/new',
  TARIFF_EDIT: '/tariffs/edit',
  TARIFF_LINK: '/tariffs/link',
  TRANSACTIONS: '/transactions',
  PERMITS: '/permits',
  PERMITS_IMPORT: '/permits/import',
  METRICS: '/metrics',
  USERS: '/users',
  USER_ADD: '/users/new',
  USER_EDIT: '/users/edit',
  EV_REPORTS: '/ev-reports',
  SETTINGS: '/settings',
}

export const AMPECO_BACKOFFICE_URL = process.env.AMPECO_BACKOFFICE_URL ?? 'https://cp.twincharge.co.uk/admin'

export const AMPECO_PATHS = {
  LOGIN: `${AMPECO_BACKOFFICE_URL}/login`,
  DASHBOARD: `${AMPECO_BACKOFFICE_URL}/dashboards/main`,
  CHARGE_POINT: (cpId: string | number) => `${AMPECO_BACKOFFICE_URL}/resources/charge-points/${cpId}`,
  EVSE: (evseId: string | number) => `${AMPECO_BACKOFFICE_URL}/resources/e-v-s-es/${evseId}`,
  SESSION: (sessionId: string | number) => `${AMPECO_BACKOFFICE_URL}/resources/sessions/${sessionId}`,
}

export const BFF_PATHS = {
  LOGIN: '/api/authentication/login',
  LOGOUT: '/api/authentication/logout',
  TOKEN: '/api/authentication/verify-token',
  PARSE_CSV: '/api/permits/parse-csv',
}

export const BFF_PROXY_PATHS = {
  BARRIERS: (organisationId: string) => `${BFF_PROXY_PATHS.ORGANISATIONS}/${organisationId}/barriers`,
  BARRIER_LOGS: `/r/barrier-logs`,
  BARRIER_RELEASE: (locationId: string, barrierId: string) =>
    `${BFF_PROXY_PATHS.LOCATION_BARRIERS(locationId)}/${barrierId}/release`,
  BARRIER_TOGGLE: (locationId: string, barrierId: string) =>
    `${BFF_PROXY_PATHS.LOCATION_BARRIERS(locationId)}/${barrierId}/toggle`,
  BOOKING: (bookingId: string) => `/r/bookings/${bookingId}`,
  BOOKINGS: '/r/bookings',
  CHANGE_PASSWORD: () => `/r/users/me/password`,
  CHARGE_POINTS: '/r/ampeco/charge-points',
  DEVICES: '/r/devices',
  DEVICES_HEARTBEATS: '/r/devices/heartbeats',
  DEVICE_ACTION: (deviceId: string, action: DeviceAction) => `/r/devices/${deviceId}/${action}`,
  DEVICE_FORCE_SYNC: (deviceId: string | number) => `/r/devices/${deviceId}/sync-config`,
  DEVICE_HEALTH: (deviceId: string) => `/r/devices/${deviceId}/health`,
  DEVICE_PING: (domain: string, protocol: Protocol, port: string) =>
    `/r/devices/ping?domain=${domain}&protocol=${protocol}&port=${port}`,
  DEVICE_SYNC_STATUS: (deviceId: string | number) => `/r/devices/${deviceId}/sync-status`,
  EV_REPORTS: (organisationId: string) => `${BFF_PROXY_PATHS.ORGANISATIONS}/${organisationId}/reports/ev`,
  LOCATION: (locationId: string) => `/r/locations/${locationId}`,
  LOCATIONS: '/r/locations',
  LOCATION_BARRIERS: (locationId: string) => `${BFF_PROXY_PATHS.LOCATION(locationId)}/barriers`,
  LOCATION_CAMERAS: (locationId: string) => `${BFF_PROXY_PATHS.LOCATION(locationId)}/cameras`,
  ORGANISATIONS: '/r/organisations',
  PERMITS: '/r/permits',
  PERMITS_IMPORT: '/r/permits/import',
  PROVIDERS: '/r/providers',
  REQUEST_PASSWORD_RESET: '/r/authentication/request-password-reset',
  RESET_PASSWORD: '/r/authentication/reset-password',
  TARIFFS: '/r/tariffs',
  TARIFFS_REMOTE: '/r/tariffs/remote',
  TRANSACTIONS: '/r/transactions',
  TRANSACTIONS_CSV: (organisationId: string) => `${BFF_PROXY_PATHS.TRANSACTIONS}/csv/${organisationId}`,
  TRANSACTIONS_METRICS: (organisationId: string) => `${BFF_PROXY_PATHS.TRANSACTIONS}/metrics/${organisationId}`,
  USERS: '/r/users',
}
