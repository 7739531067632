import { Progress } from '@mantine/core'
import React from 'react'
import { BFF_PROXY_PATHS } from 'src/constants'
import { useAxios } from 'src/hooks'

import * as S from './PermitsProcessor.styled'

interface RequestStatus {
  hasError?: boolean
  isSuccessful: boolean
  isProcessing: boolean
}

const getProgressColor = ({ hasError, isSuccessful, isProcessing }: RequestStatus) => {
  if (isProcessing) return 'blue'
  if (hasError) return 'red'
  if (isSuccessful) return 'teal'

  return 'blue'
}

const getButtonText = ({ hasError, isSuccessful, isProcessing }: RequestStatus) => {
  if (isProcessing) return 'Processing...'
  if (hasError) return 'Retry'
  if (isSuccessful) return 'Done'

  return 'Upload'
}

const getStatusText = ({ hasError, isSuccessful, isProcessing }: RequestStatus) => {
  if (isProcessing) return 'Processing...'
  if (hasError) return 'Import operation has failed'
  if (isSuccessful) return 'Import operation has completed successfully'

  return 'Ready to upload'
}

interface PermitsProcessorProps {
  disabled?: boolean
  permits: PermitEntry[]
  onSubmitSuccess?: () => void
  onSubmitError?: (error?: any) => void
  onDone?: () => void
  onRetry?: () => void
}

export const PermitsProcessor = ({
  disabled = false,
  permits,
  onSubmitSuccess,
  onSubmitError,
  onDone,
  onRetry,
}: PermitsProcessorProps) => {
  const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false)

  const [{ fetching: isProcessing, error: hasError }, submitPermits] = useAxios<Partial<IPermit>[]>({
    url: BFF_PROXY_PATHS.PERMITS_IMPORT,
    method: 'POST',
    runOnInit: false,
  })

  return (
    <S.Container>
      <S.Status>
        <S.StatusText>{getStatusText({ hasError, isSuccessful: isSubmitted, isProcessing })}</S.StatusText>
        <Progress
          value={isProcessing || hasError || isSubmitted ? 100 : 0}
          animate
          color={getProgressColor({ hasError, isSuccessful: isSubmitted, isProcessing })}
        />
      </S.Status>
      <S.UploadButton
        text={getButtonText({ hasError, isSuccessful: isSubmitted, isProcessing })}
        variant={hasError ? 'destructive' : 'accent'}
        disabled={isProcessing || disabled}
        onClick={async () => {
          if (isProcessing) return
          if (isSubmitted) {
            onDone?.()
            return
          }

          if (hasError) {
            onRetry?.()
            return
          }

          try {
            await submitPermits({ body: permits })
            setIsSubmitted(true)
            onSubmitSuccess?.()
          } catch (error) {
            setIsSubmitted(false)
            onSubmitError?.(error)
          }
        }}
      />
    </S.Container>
  )
}
