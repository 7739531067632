export const getItem = (key: string) => {
  const item = localStorage.getItem(key)
  return item ? getParsedItem(item) : null
}

function getParsedItem(data: string) {
  try {
    const o = JSON.parse(data)
    return o
  } catch (e) {
    return data
  }
}
