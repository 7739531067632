import { x } from '@xstyled/styled-components'
import { Form, Formik } from 'formik'
import _ from 'lodash'
import { AdminArea, Button, Card, RowFields } from 'src/components'
import { BFF_PROXY_PATHS } from 'src/constants/paths'
import { useAxios, useToast } from 'src/hooks'

import { LocationSettingsForm } from './components'
import { LocationSettingsSchema } from './LocationSettings.schema'

interface LocationSettingsProps {
  location?: Partial<ILocation>
  onDone: () => void
}

export const LocationSettings = ({ location, onDone }: LocationSettingsProps) => {
  const toast = useToast()
  const [, submitData] = useAxios<Partial<ILocation>>({
    method: 'PUT',
    url: `${BFF_PROXY_PATHS.LOCATIONS}/${location?.id}`,
  })

  const initialValues = location as Partial<ILocation>

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={LocationSettingsSchema}
      onSubmit={async (values: Partial<ILocation>) => {
        const fieldsToOmit = ['id', 'devices', 'tariffs', 'bays', 'tariffId', 'organisationId', 'barriers', 'cameras']
        const data = _.omit(values, fieldsToOmit)

        try {
          await submitData({ body: data })
          toast('Changes saved', { variant: 'success' })
          onDone()
        } catch (error) {
          toast('Failed to save changes', { variant: 'error' })
        }
      }}
    >
      {(formik) => {
        console.debug({ errors: formik.errors })

        return (
          <Form onSubmit={formik.handleSubmit} style={{ width: '100%', maxWidth: '650px' }}>
            <RowFields>
              <Card style={{ mt: '10px', p: '20px' }}>
                <LocationSettingsForm />

                <AdminArea>
                  <x.div display="flex" ml="auto" gap="10px" mt="40px">
                    <Button text="Cancel" variant="secondary" onClick={formik.resetForm} />
                    <Button
                      text="Save"
                      loading={formik.isSubmitting}
                      variant="accent"
                      onClick={async () => {
                        try {
                          formik.setSubmitting(true)
                          await formik.submitForm()
                        } catch (error) {
                          console.error(error)
                        } finally {
                          formik.setSubmitting(false)
                        }
                      }}
                    />
                  </x.div>
                </AdminArea>
              </Card>
            </RowFields>
          </Form>
        )
      }}
    </Formik>
  )
}
