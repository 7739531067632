import { Tabs } from '@mantine/core'
import dayjs from 'dayjs'
import React from 'react'
import { Condition, ExportCsvButton, Row } from 'src/components'
import { BFF_PROXY_PATHS } from 'src/constants'
import { Page, Shell } from 'src/containers'
import { useOrganisationContext } from 'src/context'
import { useAxios, useToast } from 'src/hooks'
import { clearEmpties, genQueryParams } from 'src/utils'
import * as TablerIcons from 'tabler-icons-react'

import { TransactionFilters } from '../Transactions/components'
import { MAX_METRICS_RANGE_DAYS } from '../Transactions/transactions.constants'

import { EvReportsTable } from './components'

export type GroupBy = 'location' | 'date'

export interface IEvReport {
  date?: string
  location?: { id: ILocation['id']; name: ILocation['name'] }
  parkingRevenue: number
  chargingRevenue: number
  consumedKWh: number
  totalRevenue: number
}

const initialFilters: TransactionFilter = {
  timestamp: {
    gt: dayjs().subtract(7, 'days').startOf('day').toISOString(),
    lt: dayjs().endOf('day').toISOString(),
  },
}

const getFilterTypes = (groupBy: GroupBy) => {
  if (groupBy === 'location') {
    return ['organisationId', 'locationId', 'deviceId', 'providerId', 'timestamp']
  }

  if (groupBy === 'date') {
    return ['organisationId', 'deviceId', 'providerId', 'timestamp']
  }

  return []
}

export const EvReports = () => {
  const { organisation } = useOrganisationContext()
  const toast = useToast()

  const [groupBy, setGroupBy] = React.useState<GroupBy>('location')
  const [filters, setFilters] = React.useState<TransactionFilter>(initialFilters)

  const [{ data: reports = [], fetching: fetchingReports }, fetchReports] = useAxios<IEvReport[]>({
    url: BFF_PROXY_PATHS.EV_REPORTS(filters?.organisationId ?? organisation?.id),
    params: {
      ...(filters && { filter: genQueryParams({ ...clearEmpties(filters), organisationId: null }) }),
      groupBy,
    },
    runOnInit: false,
  })

  const [{ fetching: fetchingCSV }, fetchEvReportsCSV] = useAxios<any>({
    url: BFF_PROXY_PATHS.EV_REPORTS(filters?.organisationId ?? organisation?.id),
    params: {
      ...(filters && { filter: genQueryParams({ ...clearEmpties(filters), organisationId: null }) }),
      groupBy,
      format: 'csv',
    },
    runOnInit: false,
  })

  React.useEffect(() => {
    if (!organisation?.id) return

    fetchReports()
  }, [organisation?.id, groupBy, filters])

  return (
    <Page
      loading={!organisation || fetchingCSV}
      endSlot={
        <Row>
          <ExportCsvButton
            onClick={fetchEvReportsCSV}
            disabled={fetchingCSV || fetchingReports || !reports?.length}
            filters={filters}
            organisationId={filters?.organisationId ?? organisation?.id}
            text="Export Reports"
            filename={`EV Transactions by ${groupBy}`}
          />
          <TransactionFilters
            initialValues={initialFilters}
            maxDaysRange={MAX_METRICS_RANGE_DAYS}
            filterSet={getFilterTypes(groupBy) as TransactionFilterTypes[]}
            onUpdate={(_, updatedFilters) => {
              setFilters(updatedFilters)
              toast('Filters applied', { variant: 'success' })
            }}
            onReset={() => toast('Filters cleared', { variant: 'default' })}
          />
        </Row>
      }
    >
      <Shell className="EvReportTableShell">
        <Condition when={!!organisation}>
          <Tabs variant="pills" onTabChange={(_tabIndex, tabKey: GroupBy) => setGroupBy(tabKey)}>
            <Tabs.Tab tabKey="location" label="By Location" icon={<TablerIcons.Location size={14} />}>
              <EvReportsTable
                reports={reports}
                fetchingReports={fetchingReports}
                groupBy={groupBy}
                timestamp={filters?.timestamp}
              />
            </Tabs.Tab>
            <Tabs.Tab tabKey="date" label="By Date" icon={<TablerIcons.CalendarEvent size={14} />}>
              <EvReportsTable
                reports={reports}
                fetchingReports={fetchingReports}
                groupBy={groupBy}
                timestamp={filters?.timestamp}
              />
            </Tabs.Tab>
          </Tabs>
        </Condition>
        <br />
      </Shell>
    </Page>
  )
}
