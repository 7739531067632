import { Tabs } from '@mantine/core'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Condition } from 'src/components'
import { BFF_PROXY_PATHS, FE_PATHS } from 'src/constants/paths'
import { Page, Shell } from 'src/containers'
import { useAuthContext } from 'src/context'
import { useAxios } from 'src/hooks'
import { genQueryParams } from 'src/utils'
import * as TablerIcons from 'tabler-icons-react'

import { LocationBays, LocationConfigs, LocationSettings, LocationTariffs } from './components'
import { LocationBarriers } from './components/LocationBarrier/LocationBarriers'
import { LocationCameras } from './components/LocationCameras'
import { LocationDevices } from './components/LocationDevices'

const getActiveTab = (tab: string, isAdmin: boolean | undefined) => {
  const DEFAULT_TAB_INDEX = 0

  switch (tab) {
    case 'settings':
      return 0
    case 'configs':
      return isAdmin ? 1 : DEFAULT_TAB_INDEX
    case 'devices':
      return 2
    case 'tariffs':
      return 3
    case 'bays':
      return 4
    case 'barriers':
      return isAdmin ? 5 : DEFAULT_TAB_INDEX
    case 'cameras':
      return isAdmin ? 6 : DEFAULT_TAB_INDEX
    default:
      return 0
  }
}

export const Location = () => {
  const params = useParams() as any
  const navigate = useNavigate()
  const browserLocation = useLocation()
  const { isAdmin } = useAuthContext()

  const currentTab = browserLocation.search?.split('?')[1]?.split('=')[0]

  const [{ data: location }, refetchLocation] = useAxios<IFullLocation>({
    url: `${BFF_PROXY_PATHS.LOCATIONS}/${params?.locationId}`,
    params: {
      include: genQueryParams({ tariffs: true, devices: true, bays: true, barriers: true, cameras: true }),
    },
  })

  const locationId = (location?.id ?? params?.locationId) as string

  return (
    <Page
      breadcrumb={{
        items: [
          { title: 'Locations', href: '/locations' },
          { title: location?.name || '', href: `/locations/${location?.id}` },
        ],
      }}
    >
      <Shell title="Location">
        <Tabs active={getActiveTab(currentTab, isAdmin)} onTabChange={() => {}}>
          <Tabs.Tab
            label="General"
            icon={<TablerIcons.Location size={14} />}
            color="teal"
            onMouseDown={() => navigate(`${FE_PATHS.LOCATION(locationId)}?settings=true`)}
          >
            <Condition when={!!location}>
              <LocationSettings location={location} onDone={refetchLocation} />
            </Condition>
          </Tabs.Tab>
          <Tabs.Tab
            hidden={!isAdmin}
            label="Configs"
            icon={<TablerIcons.Settings size={14} />}
            color="teal"
            onMouseDown={() => navigate(`${FE_PATHS.LOCATION(locationId)}?configs=true`)}
          >
            <Condition when={!!location}>
              <LocationConfigs location={location} onDone={refetchLocation} />
            </Condition>
          </Tabs.Tab>
          <Tabs.Tab
            label="Devices"
            icon={<TablerIcons.DeviceTablet size={14} />}
            color="teal"
            onMouseDown={() => navigate(`${FE_PATHS.LOCATION(locationId)}?devices=true`)}
          >
            <Condition when={!!location}>
              <LocationDevices
                devices={location?.devices}
                locationId={locationId}
                tariffs={location?.tariffs}
                onDataRefetch={refetchLocation}
              />
            </Condition>
          </Tabs.Tab>
          <Tabs.Tab
            label="Tariffs"
            icon={<TablerIcons.Tag size={14} />}
            color="teal"
            onMouseDown={() => navigate(`${FE_PATHS.LOCATION(locationId)}?tariffs=true`)}
          >
            <Condition when={!!location}>
              <LocationTariffs tariffs={location?.tariffs} onDataRefetch={refetchLocation} />
            </Condition>
          </Tabs.Tab>
          <Tabs.Tab
            label="Bays"
            icon={<TablerIcons.ChargingPile size={14} />}
            color="teal"
            onMouseDown={() => navigate(`${FE_PATHS.LOCATION(locationId)}?bays=true`)}
          >
            <LocationBays bays={location?.bays} locationId={locationId} onDataRefetch={refetchLocation} />
          </Tabs.Tab>
          {isAdmin && (
            <Tabs.Tab
              label="Barriers"
              icon={<TablerIcons.BarrierBlock size={14} />}
              color="teal"
              onMouseDown={() => navigate(`${FE_PATHS.LOCATION(locationId)}?barriers=true`)}
            >
              <LocationBarriers
                barriers={location?.barriers}
                cameras={location?.cameras}
                locationId={locationId}
                onDataRefetch={refetchLocation}
              />
            </Tabs.Tab>
          )}
          {isAdmin && (
            <Tabs.Tab
              label="Cameras"
              icon={<TablerIcons.Video size={14} />}
              color="teal"
              onMouseDown={() => navigate(`${FE_PATHS.LOCATION(locationId)}?cameras=true`)}
            >
              <LocationCameras cameras={location?.cameras} locationId={locationId} onDataRefetch={refetchLocation} />
            </Tabs.Tab>
          )}
        </Tabs>
      </Shell>
    </Page>
  )
}
