import { KeyboardEvent } from 'react'
import SearchIcon from '@mui/icons-material/Search'

import * as S from './Searchbar.styled'
export interface SearchbarProps {
  value?: string
  onSearch?: (searchTerm: string) => void
  sanitizer?: (searchTerm: string) => string
  searchPlaceholder?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export default function Searchbar({
  onSearch,
  sanitizer,
  value,
  searchPlaceholder = 'Search for things',
  onChange,
}: SearchbarProps) {
  return (
    <S.$Container>
      <S.$Icon>
        <SearchIcon fontSize="small" />
      </S.$Icon>
      <S.$Search>
        <S.$Input
          placeholder={searchPlaceholder}
          value={value}
          onInput={(event: KeyboardEvent<HTMLInputElement>) => {
            const { value } = event.target as HTMLInputElement

            const sanitizedValue = sanitizer ? sanitizer(value) : value
            onSearch?.(sanitizedValue)
          }}
          onChange={onChange}
        />
      </S.$Search>
    </S.$Container>
  )
}
