import { x } from '@xstyled/styled-components'
import { Form, useFormikContext } from 'formik'
import React from 'react'
import {
  Condition,
  Divider,
  Flex,
  FormikSelectDropdown,
  FormikSelectDropdownProps,
  ImageUpload,
  InputField,
  InputFieldProps,
  RowFields,
  ToggleField,
} from 'src/components'
import { IDevice } from 'src/typings/device'
import { isPayStation } from 'src/utils'

import { PingDeviceButton } from './components/PingDeviceButton'

const DeviceInputField = (props: InputFieldProps<Partial<IDevice>>) => InputField<Partial<IDevice>>(props)
const DeviceSelectDropdown = (props: FormikSelectDropdownProps<Partial<IDevice>>) =>
  FormikSelectDropdown<Partial<IDevice>>(props)

interface DeviceFormProps {
  onChange?: (data: Partial<IDevice>) => void
  device?: Partial<IDevice>
  tariffs?: ITariff[]
  isActive?: boolean
  errors?: any
}

export default function DeviceForm({ device, onChange, tariffs, isActive, errors }: DeviceFormProps) {
  const formik = useFormikContext<Partial<IDevice>>()

  const [supportsPayments, setSupportsPayments] = React.useState<boolean>(isPayStation(device?.type))

  React.useEffect(() => {
    formik?.resetForm()
    formik.setValues(device ?? {}, false)
    // eslint-disable-next-line
  }, [location, isActive])

  React.useEffect(() => {
    onChange?.(formik.values)
    // eslint-disable-next-line
  }, [formik.values])

  return (
    <Form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
      <x.div display="flex">
        <x.div w="100%">
          <x.div display="flex" mb="24px">
            <x.div w="50%">
              <x.h4 mb="15px">
                <strong>LOGO</strong>
              </x.h4>
              <ImageUpload
                dataUrl={formik.values.logo as string}
                accept="image/*"
                label="Logo"
                size={100}
                onFileChoose={(_, logoData) => {
                  formik.setFieldValue('logo', logoData)
                }}
              />
            </x.div>
            <x.div w="50%">
              <x.h4 mb="15px">
                <strong>DETAILS</strong>
              </x.h4>
              <DeviceInputField placeholderName="device" name="name" label="Name" error={errors.name} />
            </x.div>
          </x.div>

          <Divider light />
          <br />

          <Flex direction="column" justify="space-between" style={{ minWidth: '100%' }}>
            <x.h4 mb="15px">
              <strong>SETTINGS</strong>
            </x.h4>
            <RowFields>
              <DeviceSelectDropdown
                containerProps={{ flex: '1', minWidth: '100px' }}
                name="protocol"
                label="Protocol"
                error={errors.protocol}
                onChange={(values) => {
                  const selected = values?.[0]
                  const protocol = selected?.value || 'http'

                  formik.setFieldValue('protocol', protocol)

                  if (protocol === 'https') {
                    formik.setFieldValue('remotePort', '443')
                  } else if (protocol === 'http') {
                    formik.setFieldValue('remotePort', '80')
                  }
                }}
                items={[
                  { label: 'HTTP', value: 'http' },
                  { label: 'HTTPS', value: 'https' },
                ]}
              />
              <DeviceInputField
                containerProps={{ flex: '5' }}
                placeholderName="device"
                name="remoteIp"
                label="Domain"
                error={errors.remoteIp}
              />
              <DeviceInputField
                containerProps={{ flex: '1' }}
                placeholderName="device"
                name="remotePort"
                label="Port"
                error={errors.remotePort}
              />
            </RowFields>
            <RowFields>
              <DeviceSelectDropdown
                placeholder="Select device's type"
                name="type"
                label="Type"
                error={errors.type}
                onChange={(values) => {
                  const selected = values?.[0]
                  if (selected?.value === 'access_control') formik.setFieldValue('parkingTariffId', null)

                  formik.setFieldValue('type', selected?.value)
                  setSupportsPayments(isPayStation(selected?.value))

                  formik.setFieldValue('supportsPrinting', isPayStation(selected?.value))
                }}
                items={[
                  { label: 'Paystation (entry)', value: 'pay_on_entry' },
                  { label: 'Paystation (exit)', value: 'pay_on_exit' },
                  { label: 'Access control', value: 'access_control' },
                  { label: 'Whitelist', value: 'whitelist' },
                ]}
              />
              <Condition when={formik.values.type === 'access_control'}>
                <DeviceInputField
                  placeholder="Relay switch ID"
                  name="relaySwitchId"
                  label="Relay switch ID"
                  error={errors.relaySwitchId}
                />
              </Condition>
              <Condition when={formik.values.type === 'whitelist'}>
                <DeviceInputField
                  placeholder="Free whitelist minutes"
                  name="whitelistMinutes"
                  label="Free whitelist minutes"
                  error={errors.whitelistMinutes}
                  type="number"
                  inputProps={{
                    defaultValue: 0,
                    min: 0,
                    onInput: (e) => {
                      const input = e.target as HTMLInputElement
                      if (input.value && parseInt(input.value) < 0) {
                        input.value = '0'
                      }
                    },
                  }}
                />
              </Condition>
              <Condition when={['pay_on_entry', 'pay_on_exit'].includes(formik.values.type as string)}>
                <DeviceSelectDropdown
                  placeholder="Select parking tariff"
                  name="parkingTariffId"
                  label="Parking tariff"
                  error={errors.parkingTariffId}
                  onChange={(values) => {
                    const selected = values?.[0]
                    formik.setFieldValue('parkingTariffId', selected?.value)
                  }}
                  items={
                    tariffs
                      ?.filter(({ type }) => type === 'regular')
                      ?.map(({ name, id }) => ({ label: name, value: id })) || []
                  }
                />
              </Condition>
            </RowFields>
          </Flex>

          <Divider light />
          <Flex direction="row">
            <x.div w="100%" mr="40px">
              <x.div mb="20px">
                <ToggleField
                  disabled={formik.values.type === 'access_control' || formik.values.type === 'whitelist'}
                  name="supportsPrinting"
                  label="PRINTER"
                  checked={Boolean(formik.values.supportsPrinting)}
                  onChange={(checked) => {
                    formik.setFieldValue('supportsPrinting', checked)
                  }}
                />
              </x.div>
              <Condition when={formik.values.supportsPrinting}>
                <div>
                  <DeviceInputField
                    name="printerIp"
                    placeholder="IP address"
                    label="Printer IP"
                    error={errors.printerIp}
                    type="string"
                  />
                </div>
                <div>
                  <DeviceInputField
                    name="printerDefaultPrints"
                    placeholder="Prints Capacity"
                    label="Prints Capacity"
                    error={errors.printerDefaultPrints}
                    type="number"
                  />
                </div>
              </Condition>
            </x.div>
            <x.div w="100%">
              <x.div mb="20px">
                <ToggleField disabled label="PAYMENT DEVICE" checked={supportsPayments} />
              </x.div>
              <Condition when={supportsPayments}>
                <div>
                  <DeviceInputField
                    name="alioIp"
                    placeholder="IP address"
                    label="Payment Device IP"
                    type="string"
                    error={errors.alioIp}
                  />
                </div>
                <div>
                  <DeviceInputField
                    name="alioPort"
                    placeholder="Port number"
                    label="Payment Device Port"
                    type="string"
                    error={errors.alioPort}
                  />
                </div>
              </Condition>
            </x.div>
          </Flex>
          <Flex>
            <PingDeviceButton formik={formik} />
          </Flex>
        </x.div>
      </x.div>
    </Form>
  )
}
