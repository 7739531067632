import React from 'react'
import { Modal } from 'src/components'
import { BFF_PROXY_PATHS } from 'src/constants/paths'
import { useAxios } from 'src/hooks'

interface Props {
  isOpen: boolean
  permit: IPermit
  onDelete: (permit: Partial<IPermit>) => void
  onClose: () => void
}

export const DeletePermitModal = ({ isOpen, permit, onDelete, onClose }: Props) => {
  const [, deletePermit] = useAxios<IPermit>({
    method: 'DELETE',
    url: `${BFF_PROXY_PATHS.PERMITS}/${permit?.id}`,
    runOnInit: false,
  })

  const handleDeleteConfirm = React.useCallback(async () => {
    if (!permit) return

    await deletePermit({ params: { id: permit.id } })

    onDelete(permit)
    onClose()
  }, [permit, deletePermit, onDelete, onClose])

  return (
    <Modal
      isOpen={isOpen}
      cardStyle={{ maxWidth: 450 }}
      title="Are you sure?"
      body={
        <h4>
          The permit with reference <strong>{permit?.reference}</strong> will be permanently deleted. This action is
          irreversible!
        </h4>
      }
      onConfirm={handleDeleteConfirm}
      confirmText="Delete"
      onClose={onClose}
    />
  )
}
